@font-face {
    font-family: 'Louis George Cafe';
    src: url('LouisGeorgeCafe.woff2') format('woff2'),
        url('LouisGeorgeCafe.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/* @font-face {
    font-family: 'Gogh Extra';
    src: url('Gogh-ExtraBoldItalic.woff2') format('woff2'),
        url('Gogh-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
} */

@font-face {
    font-family: 'Gogh Extra';
    src: url('Gogh-ExtraBold.woff2') format('woff2'),
        url('Gogh-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

