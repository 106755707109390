
@media (max-width: 767px) {
    .navbar-toggler {
        background-color: #fff !important;
        border-color: #fff !important;
    }
    .mobile-nav {
        width: 100%;
    
        .navbar-collapse {
            background-color: #ffffff9c;
            padding: 10px;
            border-radius: 5px;
              
    margin-top: 10px;
           .navbar-nav {
            .nav-item {
                margin-bottom: 10px;
                .nav-link {
                    margin: 0 !important;
                }
                &.dropdown {
                    .nav-link {
                        padding-left: 1rem !important;
                        padding-right: 1rem !important;
                    }
                }
            }
           }
          }
    }
   
    // page css
    .btn-mobile {
        font-size: 16px !important;
    }
.m-w-100 {
    width: 100% !important;
}
.height-auto {
    height: auto !important;
}
.p-tag-title {
    font-size: 24px;
    text-align: center;
}
    .page-title {
        font-size: 32px !important;
        line-height: 1.2 !important;
    margin: 20px 0 20px!important;
    text-align: center;
    }
    .p-text {
        font-size: 15px !important;
    }
    .dt-laptop-slider {
        margin: 20px 0 !important;
        padding-bottom: 0 !important;
    }

    .countContainer {
        padding: 0 !important;
        margin: 0 !important;
        .count {
            font-size: 33px !important;
            margin-bottom: 20px;
        }
    }
    .MuiContainer-root {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }
    .MuiGrid-item {
        margin: 0 !important;
        padding-top: 0 !important;
    }
    .MuiTypography-h3 {
        font-size: 33px !important;
        text-align: center;
    }
    .MuiTypography-body2 {
        font-size: 15px !important;
        width: 100% !important;
    }
    .buttons-item {
        .MuiGrid-item {
            height: 60px !important;
            padding: 0 !important;
            margin-bottom: 15px !important;
        }
        .MuiBox-root {
            height: 60px !important;
        }
    }

    .team-btn-conatiner {
        .MuiBox-root {
            height: 70px !important;
            margin-bottom: 10px;
        }
    }
    .our-team {
        padding-top: 2em !important;
        padding-bottom: 2rem !important;
        float: left;
        p {
            margin: 20px !important;
            font-size: 43px !important;
            line-height: 1.2;
        }
    }
    .touch-form {
        .MuiGrid-root {
            margin: 0 !important;
            width: 100% !important;
        }
        .MuiGrid-item {
            margin: 0 !important;
            padding: 10px !important;
        }
        .MuiButton-root {
            margin-bottom: 0 !important;
        }
    }
  }