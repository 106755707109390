.countContainer{
    /* border-right: 1px solid #344B87; */
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
   
   }
   
   .count{
       font-size: 10rem;
       font-weight: 700;
       font-family:'Gogh Extra';
       color: #344B87;
   }