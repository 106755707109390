.dt-content-center {
  width: 50%;
  /* float: center; */
  /* display: flex;
    justify-content: center; */
}

.dt-laptop-slider {
  /* background: url("../assets/contract_mng.png"); */
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 4rem;
  padding-bottom: 7rem;
  /* z-index: 4;
    background-size: contain;
    height: 0;
    padding: 0;
    padding-bottom: 40%;
    margin-top: 30%; */
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.app-shot {
  width: 56%;
  height: 57.5%;
  position: absolute;
  top: 50;
  left: 0;
  margin-left: 3.5rem;
  margin-top: 0.8rem;
  width: "56%";
  height: "57.5%";
  /* display: flex;
  justify-content: center; */
}

@media screen and (max-width: 1212px) {
    .laptop-screen {
      /* display: none; */
      width: 100%;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin-top: 4rem;
      padding-bottom: 7rem;
    }
  
    .app-shot {
      position: absolute;
      /* background-size: cover; */
      top: 0;
      left: 0;
      width: 76%;
      height: 43%;
      margin-top: 5rem;
      margin-left: 4rem;
    }
  }

/* laptop */
@media screen and (max-width: 770px) {
  .laptop-screen {
    /* display: none; */
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 4rem;
    padding-bottom: 7rem;
  }

  .app-shot {
    position: absolute;
    /* background-size: cover; */
    top: 0;
    left: 0;
    width: 76.5%;
    height: 50%;
    margin-top: 4.9rem;
    margin-left: 5rem;
  }
}

/* mobile */
@media screen and (max-width: 480px) {
  .laptop-screen {
    /* display: none; */
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 0rem;
    padding-bottom: 0rem;
  }

  .app-shot {
    position: absolute;
    /* background-size: cover; */
    top: 9px;
    left: 0;
    width: 76%;
    height: 81%;
    margin-top: 0;
    margin-left: 47px;
  }
}
